.profile-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
}

.profile-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 2vw;
  padding: 4vw;
  font-size: 1.5vw;
  grid-template-columns: 1fr;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
}

.profile-label {
  font-size: 2.5vw;
}
/* 
@media screen and (max-width: 992px) {
  .profile-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
  }

  .profile-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 2vw;
      padding: 4vw;
    font-size: 1vw;
    grid-template-columns: 1fr;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
    border-radius: 0.5vw;
  }

  .profile-label {
    font-size: 2vw;
  }
} */

.profile-table-column-center {
  text-align: center;
}

.profile-table-bold {
  font-weight: 700;
}
