.product-card-pane {
  margin: 0.2vw;
  width: 100%;
}

.product-card-container {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
  width: 100%;
  font-size: 1.3vw;
  border: 0.1vw solid cornflowerblue;
}

.product-card-vertical-segment {
  display: flex;
  flex-direction: column;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  /* padding-top: 0.2vw; */
  border-radius: 0vw 0vw 0.5vw 0.5vw;
}

.product-card-horizontal-segment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  white-space: nowrap;
}

.product-card-horizontal-delimiter {
  border-top: 0.1vw solid cornflowerblue;
  /* margin-top: 0.2vw; */
}

.product-card-vertical-delimiter {
  border-right: 0.1vw solid cornflowerblue;
  margin-left: 0.2vw;
  margin-right: 0.2vw;
}

.product-card-attribute-label {
  font-weight: 700;
  font-size: 1.4vw;
}

.product-card-attribute-value {
  padding-left: 0.2vw;
}

.product-card-id-label {
  font-size: 1.4vw;
}

.product-card-padding-bottom {
  padding-bottom: 0.2vw;
}

.product-card-link-hover:hover {
  cursor: pointer;
  border: 0.1vw solid orange;
  border-radius: 0.5vw;
}
