.swal2-container {
  position: absolute !important;
}

.shipments-dashboard-main {
  display: flex;
  flex-direction: column;
  width: 97vw;
}

.shipments-dashboard-container {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-start;
}

.shipments-dashboard-container-empty {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  min-height: 80vh;
}

.shipments-dashboard-empty-section {
  display: flex;
  justify-content: center;
  padding: 4vw;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
}

.shipments-dashboard-empty-label {
  padding: 1vw;
  font-size: 2.4vw;
}

.shipments-dashboard-buttons-section {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
  padding: 0.2vw;
  padding-left: 1vw;
  margin: 0.5vw;
}

.shipments-dashboard-section {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-start;
}
/* 
@media screen and (max-width: 992px) {
  .shipments-dashboard-container {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    justify-content: flex-start;
  }
} */
