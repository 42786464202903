.prepared-shipments-bucket-segment {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5vw;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
  width: 22vw;
  min-width: 22vw;
  min-height: 85vh;
  margin: 0.5vw;
}

.prepared-shipments-bucket-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
}

.prepared-shipments-bucket-title-icon {
  font-size: 1.3vw;
  color: orange;
}

.prepared-shipments-bucket-title-label {
  font-size: 1.3vw;
  padding-left: 0.3vw;
  font-weight: 700;
}
/*
@media screen and (max-width: 992px) {
  .prepared-shipments-bucket-segment {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5vw;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
    border-radius: 0.5vw;
    width: 100%;
    min-height: 82vh;
    margin: 0.5vw;
    width: 22vw;
  }
} */
